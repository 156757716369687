import React, { useState } from "react";

export const UserContext = React.createContext();

export default function UserProvider(props) {
  const [users, setUsers] = useState([]);

  return (
    <UserContext.Provider value={{ setUsers, users }}>
      {props.children}
    </UserContext.Provider>
  );
}
